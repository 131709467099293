































































































import {
  computed,
  defineComponent,
  PropType,
  ref,
  toRefs
} from '@nuxtjs/composition-api'
import MakeOptionWithLogoAndCount from '~/components/car/classifieds/search/facets/handlers/MakeModel/MakeOptionWithLogoAndCount.vue'
import CTag from '~/components/shared/configurable/form/tag/CTag.vue'
import CCustomSelectDeselectAllButton from '~/components/shared/configurable/form/select/custom/CCustomSelectDeselectAllButton.vue'
import CCategoryIcon from '~/components/shared/configurable/category/CCategoryIcon.vue'
import { Option } from '~/models/shared/types'
import {
  CLASSIFIED_SEARCH_NS,
  SearchState
} from '~/store/modules/shared/classifieds/search/state'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useNamespacedStore } from '~/compositions/store'
import { useI18n } from '~/compositions/i18n'
import { useFormComponent } from '~/compositions/form-component'
import { SelectSize } from '~/models/app/select'

export default defineComponent({
  components: {
    MakeOptionWithLogoAndCount,
    CTag,
    CCustomSelectDeselectAllButton,
    CCategoryIcon
  },
  props: {
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    headerClasses: {
      type: String,
      required: true
    },
    mainTextClasses: {
      type: String,
      default: ''
    },
    dropdownDirection: {
      type: String,
      required: false,
      default: 'below'
    },
    headerPreText: {
      type: String,
      required: false,
      default: null
    },
    selectedOptions: {
      type: Array as PropType<Option[]>,
      required: true
    },
    internalDropdownOpen: {
      type: Boolean,
      required: true
    },
    size: {
      type: String as PropType<SelectSize>,
      required: true
    },
    canDeselectSingle: {
      type: Boolean,
      required: false,
      default: false
    },
    multiSelect: {
      type: Boolean,
      required: true
    },
    headerTitle: {
      type: String,
      required: false,
      default: null
    },
    state: {
      type: Boolean,
      default: null
    },
    warning: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      required: false,
      default: ''
    },
    suffix: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    withMakeLogo: {
      type: Boolean,
      default: false
    },
    showSelectedTags: {
      type: Boolean,
      default: true
    },
    nothingSelected: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    const {
      headerClasses,
      size,
      dropdownDirection,
      selectedOptions,
      state,
      disabled,
      internalDropdownOpen,
      warning,
      nothingSelected
    } = toRefs(props)

    const { t } = useI18n()

    const { state: searchState } = useNamespacedStore<SearchState>(
      CLASSIFIED_SEARCH_NS
    )
    const isQuickSearch = computed(() => searchState.isQuickSearch)

    const firstOption = computed(() => {
      if (typeof selectedOptionSet.value[0] === 'string') {
        return selectedOptionSet.value[0]
      }
      return (
        selectedOptionSet.value[0].headerText || selectedOptionSet.value[0].text
      )
    })
    const placeholderWhenShowNoTags = computed(() => {
      return selectedOptionSet.value.length === 1
        ? firstOption.value
        : `${selectedOptionSet.value.length} ${t('selected')
            .toString()
            .toLowerCase()}`
    })

    const { controlClasses } = useFormComponent(
      ref(''),
      ref(state.value === false),
      ref(state.value === true),
      warning,
      'select'
    )

    const textColorClass = computed(() =>
      nothingSelected.value || disabled.value
        ? 'tw-text-grey-600'
        : 'tw-text-grey-900'
    )

    const computedClasses = computed((): any[] => {
      const classes = [headerClasses.value, size.value, dropdownDirection.value]
      if (disabled.value) {
        classes.push(
          ...['tw-bg-grey-300', 'tw-cursor-not-allowed', 'pointer-events-none']
        )
      } else {
        classes.push(...['tw-bg-white', 'tw-cursor-pointer'])
      }
      if (isQuickSearch.value) {
        classes.push(...['tw-rounded-lg'])
      } else {
        classes.push('tw-rounded-md')
      }

      if (internalDropdownOpen.value) {
        if (state.value) {
          classes.push('tw-ring-green-500', '!tw-border-green-500')
        } else if (state.value === false) {
          classes.push('tw-ring-red-500', '!tw-border-red-500')
        } else if (warning.value) {
          classes.push('tw-ring-amber-500', '!tw-border-amber-500')
        } else {
          classes.push('tw-ring-blue-500', '!tw-border-blue-500')
        }
        classes.push('tw-ring-1')
      }

      return [...classes, ...controlClasses.value, textColorClass.value]
    })

    const caretRotation = computed(() => {
      return internalDropdownOpen.value ? 180 : null
    })

    const selectedOptionSet = computed((): Option[] | string[] => {
      const set = new Set<string>()
      const options = []
      for (const o of selectedOptions.value) {
        const duplicateName: boolean = set.has(o.text)
        set.add(o.text)
        if (duplicateName) {
          continue
        }

        options.push(o)
      }
      return options
    })

    const separatedSelectedOptions = computed((): string => {
      return selectedOptionSet.value
        .map(option =>
          typeof option === 'string' ? option : option.headerText || option.text
        )
        .join(', ')
    })

    const handleClick = () => {
      emit('click')
    }

    return {
      handleClick,
      selectedOptionSet,
      caretRotation,
      separatedSelectedOptions,
      computedClasses,
      placeholderWhenShowNoTags,
      faChevronDown,
      isQuickSearch,
      textColorClass,
      firstOption
    }
  }
})
